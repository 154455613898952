<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro'}} de Pontos</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 420px;">
                <v-text-field
                    label="Nº Pedido"
                    v-model="form.pedido_id"
                    @change="onChangePedido"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <CustomerSearchAutocomplete
                    v-model="form.cliente"
                    @update="onClienteUpdate"
                    @reset="cliente = null"
                />
                <v-select
                    :items="operacoes"
                    v-model="form.tipo"
                    label="Tipo operação"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-text-field
                    label="Pontos"
                    v-model="form.pontos"
                    :prefix="getPontosPrefix"
                    v-mask="['#######']"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-textarea
                    v-model="form.observacao"
                    clear-icon="mdi-backspace-outline"
                    label="Observacao"
                    dense
                    hide-details
                    clearable
                    outlined
                    class="mt-3"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CustomerSearchAutocomplete from '@/pages/home/Pedido/CustomerSearchAutocomplete';
import { mapMutations } from 'vuex';

export default {
    name: 'Pontos',

    components: {
        CustomerSearchAutocomplete,
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        loading: false,
        form: {
            pedido_id: '',
            cliente_id: '',
            observacao: '',
            pontos: '',
            tipo: 'credito',
        },
        operacoes: [
            {
                text: 'Crédito',
                value: 'credito',
            },
            {
                text: 'Débito',
                value: 'debito',
            },
        ],
    }),

    mounted() {
        this.form = this.formData;
    },

    computed: {
        getPontosPrefix() {
            if (!this.form.tipo) {
                return '';
            }

            return this.form.tipo === 'credito' ? '+' : '-';
        },
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        salvar() {
            if (!this.form.tipo) {
                this.notify('Informe o tipo de operação', 'warning');
                return;
            }
            if (!this.form.cliente_id) {
                this.notify('Informe o cliente', 'warning');
                return;
            }
            if (!this.form.pontos) {
                this.notify('Informe os pontos', 'warning');
                return;
            }
            if (!this.form.observacao) {
                this.notify('Informe a observação', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('pontos-movimentacoes', this.form).then(resp => {
                this.$emit('onCadastro', resp.data.data);
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        onClienteUpdate(cliente) {
            this.form.cliente_id = cliente.id;
        },

        onChangePedido() {
            this.$http.get(`pedidos/${this.form.pedido_id}`).then(resp => {
                this.form.cliente = resp.data?.cliente || null;
                this.$forceUpdate();
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
